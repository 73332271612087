import React from 'react'
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  Animation,
} from 'mdbreact'

const Intro = ({ children }) => {
  return (
    <div className="vw-100 vh-100 position-absolute" style={{zIndex: 100}}>
      <div className="h-100 d-flex justify-content-center align-items-center white-text">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="col-md-12 text-center">
              <h2 className="h1-responsive font-weight-bold white-text mb-0 pt-md-5 pt-5">
                M&amp;M Consulting
              </h2>
              <hr className="hr-light my-4 w-75" />
              <h4 className="subtext-header h4-responsive mt-2 mb-4">
                Consulting so good, it melts in your mouth.
              </h4>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="col-md-12 text-center">
              <Animation type="rubberBand" duration="2s">
              <MDBBtn size="lg" color="indigo" style={{marginTop: '2em'}}>
                <MDBIcon icon="home" className="mr-2" />{' '}
                <a className="white-text" href="mailto:website@mmconsult.com">
                  Email us!
                </a>
              </MDBBtn>
              </Animation>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  )
}

export default Intro
